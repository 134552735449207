import { IUser } from '@on3/ui-lib/api/schema/custom-contracts';

import isMobile from '../device';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bidroll: any;
    incUnits: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    googletag: any;
  }
}

interface slotObject {
  elementId: string;
  inherit: string;
  increment: number;
}
type SlotType = slotObject | string;

interface FilterProps {
  pageType: string;
  device: string;
  template?: string | null;
  names: string[];
  userStatus?: string;
}
interface UnitProps {
  name: string;
  position?: string | null;
  count?: number | null;
  pageType?: string[] | null;
  device?: string | null;
  template?: string | null;
}
interface IAdsProps {
  pageType: string;
  team?: string | null;
  contentID?: number;
  authorId?: number;
  page?: number | null;
  incUnits?: number;
  siteType?: string | null;
  template?: string;
  categoryKey?: number | null;
  siteKey?: number;
  resetInc?: boolean;
  tags?: string;
  user?: IUser;
}

interface IPageTargeting {
  ptype: string;
  referrer: string;
  team: string;
  contentId?: number;
  authorId?: number;
  siteType?: string | null;
  categoryKey?: number | null;
  userStatus?: string;
  siteKey?: number;
  tags?: string;
}

export const initializeAds = async ({
  pageType,
  team,
  contentID,
  authorId,
  page,
  incUnits,
  siteType,
  template,
  categoryKey,
  siteKey,
  resetInc = true,
  tags,
  user,
}: IAdsProps) => {
  const referrer = formatReferrer(document.referrer);
  const userStatus = user?.a ? user?.st : 'guest';
  const pageTargeting: IPageTargeting = {
    ptype: pageType,
    referrer,
    team: team || '',
    siteType,
    userStatus,
    siteKey,
    tags,
  };

  if (resetInc) {
    resetIncCounts();
  }

  if (incUnits) {
    const addUnits = page === 0 ? incUnits - 1 : incUnits;

    window.incUnits = window?.incUnits ? window?.incUnits + addUnits : addUnits;
  }

  if (contentID) {
    pageTargeting.contentId = contentID;
    pageTargeting.authorId = authorId;
    pageTargeting.categoryKey = categoryKey || 0;
    pageTargeting.tags = tags;
  }

  window.bidroll.queue.push(() => {
    const cfg = {
      adPath: `/4670326/${isMobile() ? 'mw-ott' : 'dw-ott'}/${pageType}`,
      pageTargeting,
      firstPartyData: { user: { email: user?.e } },
    };

    if (window?.bidroll.status === 'loaded') {
      window?.bidroll.init(cfg);
    } else {
      window?.bidroll.newPageView({ ...cfg, destroyActiveUnits: true });
    }

    let slots = filterUnitNames({
      pageType,
      device: isMobile() ? 'mobile' : 'desktop',
      inc: incUnits || 0,
      template,
      page: page || 0,
      userStatus,
    });

    slots = filterVisibleSlots(slots);

    if (
      userStatus !== 'annual' &&
      userStatus !== 'monthly' &&
      !userStatus?.includes('premium')
    ) {
      let adjustedSlots = slots;

      adjustedSlots = slots.filter(
        (slot: SlotType) => slot !== 'mobile_adhesion_top',
      );

      window.bidroll.refresh(adjustedSlots);
    } else {
      let adjustedSlots = slots;

      adjustedSlots = slots.filter(
        (slot: SlotType) => slot !== 'mobile_adhesion_top_guest',
      );

      if (pageType === 'article') {
        adjustedSlots = adjustedSlots.filter(
          (slot: SlotType) => slot !== 'mobile_adhesion_top',
        );

        if (!isMobile() && template === 'traditional') {
          adjustedSlots = adjustedSlots.filter((slot: SlotType) => {
            if (typeof slot === 'object' && slot !== null) {
              return slot.inherit !== 'mpu_inc';
            }

            return true;
          });
        }
      }

      window.bidroll.refresh(adjustedSlots);
    }
  });
};

const formatReferrer = (referrer: string): string => {
  if (referrer === '') {
    return 'direct';
  } else if (referrer.match(/facebook\.com/g)) {
    return 'facebook';
  } else if (referrer.match(/googlequicksearchbox/g)) {
    return 'discover';
  } else if (referrer.match(/googleapis/g)) {
    return 'googlecards';
  } else if (referrer.match(/news\.google\.com/g)) {
    return 'googlenews';
  } else if (referrer.match(/ampproject\.org/g)) {
    return 'amp';
  } else if (referrer.match(/t\.co/g)) {
    return 'twitter';
  } else if (referrer.match(/pinterest\.com/g)) {
    return 'pinterest';
  } else if (referrer.match(/instagram\.com/g)) {
    return 'instagram';
  } else if (referrer.match(/newsbreak/g)) {
    return 'newsbreak';
  } else if (referrer.match(/smartnews\.com/g)) {
    return 'smartnews';
  } else if (
    referrer.match(
      /(google|duckduckgo|bing|yahoo|search|aol\.com|ecosia\.org)/g,
    )
  ) {
    return 'search';
  }

  return 'other';
};

const filterVisibleSlots = (slots: SlotType[]) => {
  return slots.filter((slot: SlotType) => {
    let elementId;

    if (typeof slot === 'string') {
      elementId = slot;
    } else if (slot.elementId) {
      elementId = slot.elementId;
    }

    return elementId && document.getElementById(elementId);
  });
};

function filterUnitNames({
  pageType = 'article',
  device = 'desktop',
  template = 'Traditional',
  inc = 0,
  page = 0,
  userStatus = 'guest',
}) {
  const units = adUnitList.filter(
    (unit: UnitProps) =>
      unit?.pageType?.includes(pageType) &&
      unit.device === device &&
      unit?.template !== 'Feed',
  );

  if (template === 'Feed' && pageType === 'article' && device === 'desktop') {
    units.push({
      name: 'mpu_inc',
      device: 'desktop',
      pageType: ['article'],
      position: 'inc',
      count: 1,
    });
  }

  let incCounter = 0;
  let returnedUnits: SlotType[] = [];

  for (let i = 0; i < units.length; i++) {
    const unit = units[i];

    if (unit?.name === 'mobile_mpu_top') {
      incCounter++;
    }

    // count how many inc's in content to send to bidroll
    if (unit?.name === 'mobile_mpu_inc' || unit?.name === 'mpu_inc') {
      for (let j = incCounter; j < inc; j++) {
        const number = unit?.count;

        unit.count = number && number + 1;

        returnedUnits.push({
          elementId: `${unit.name}-${number}`,
          inherit: unit.name,
          increment: number || 0,
        });
      }
    } else if (unit?.position === 'inc') {
      const number = unit?.count;

      unit.count = number && number + 1;

      returnedUnits.push({
        elementId: `${unit.name}-${number}`,
        inherit: unit.name,
        increment: number,
      });
    }

    if (unit?.position !== 'inc') {
      returnedUnits.push(unit.name);
    }
  }

  if (pageType === 'article' && template === 'Traditional') {
    if (device === 'mobile') {
      returnedUnits.push(
        {
          elementId: `mobile_mpu_bottom`,
          inherit: 'mobile_mpu_bottom',
          increment: 1,
        },
        {
          elementId: `mobile_mpu_bottom`,
          inherit: 'mobile_mpu_bottom',
          increment: 2,
        },
        {
          elementId: `mobile_mpu_bottom`,
          inherit: 'mobile_mpu_bottom',
          increment: 3,
        },
        {
          elementId: `mobile_mpu_bottom`,
          inherit: 'mobile_mpu_bottom',
          increment: 4,
        },
        {
          elementId: `mobile_mpu_bottom`,
          inherit: 'mobile_mpu_bottom',
          increment: 5,
        },
      );
    }
  }

  if (pageType === 'article' && template === 'Feed') {
    if (device === 'desktop') {
      returnedUnits.push({
        elementId: `mpu_bottom-${page + 1}`,
        inherit: 'mpu_bottom',
        increment: 1,
      });
    } else {
      returnedUnits.push({
        elementId: `mobile_mpu_bottom-${page + 1}`,
        inherit: 'mobile_mpu_bottom',
        increment: 1,
      });
    }
  }

  if (pageType !== 'article') {
    const startingUnit = window?.incUnits - inc || 0;

    let tempIncUnit = 'mobile_mpu_inc';

    if (template === 'Feed' && pageType === 'home') {
      returnedUnits.push('image_top');

      if (device === 'desktop') {
        returnedUnits.push('desktop_adhesion');
      }

      if (device === 'mobile') {
        returnedUnits = returnedUnits.filter(
          (unit: SlotType) =>
            unit !== 'mobile_mpu_top' && unit !== 'mobile_mpu_middle',
        );
      }
    }

    if (template === 'Traditional' && pageType === 'home') {
      returnedUnits.push('mobile_mpu_top');
    }

    if (device === 'desktop') {
      tempIncUnit = 'mpu_inc';
    }

    for (let i = startingUnit; i < startingUnit + inc; i++) {
      const number = i + 1;

      if (document.getElementById(`${tempIncUnit}-${number}`)) {
        returnedUnits.push({
          elementId: `${tempIncUnit}-${number}`,
          inherit: tempIncUnit,
          increment: number,
        });
      }
    }
  }

  return returnedUnits;
}

const returnAdUnitsByName = ({
  names = [],
  template,
  device,
  userStatus,
}: FilterProps) => {
  const adUnits = names.map((adUnit) => {
    return adUnitList.find((unit) => unit.name === adUnit);
  });

  if (template === 'Feed') {
    if (device === 'desktop') {
      adUnits.push({
        name: 'mpu_inc',
        device: 'desktop',
        pageType: ['article'],
        position: 'inc',
        count: 2,
      });
      adUnits.push({
        name: 'mpu_bottom',
        device: 'desktop',
        pageType: ['article'],
        position: 'inc',
        count: 2,
      });
    } else {
      adUnits.push({
        name: 'mobile_mpu_bottom',
        device: 'mobile',
        pageType: ['article'],
        position: 'inc',
        count: 2,
      });
    }
  }

  return adUnits.map((unit) => {
    // count how many inc's in content to send to bidroll
    if (unit?.position === 'inc') {
      const number = unit?.count;

      unit.count = number && number + 1;

      return {
        elementId: `${unit.name}-${number}`,
        inherit: unit.name,
        increment: number,
      };
    }

    return unit?.name;
  });
};

let initialNumber = 0;
const getIncCount = () => {
  initialNumber = initialNumber + 1;

  return initialNumber;
};

const adUnitList = [
  {
    name: 'mpu_top',
    device: 'desktop',
    pageType: [
      'home',
      'category',
      'author',
      'database',
      'shows',
      'profile',
      'nil',
      'draft',
      'article',
    ],
  },
  {
    name: 'mpu_middle',
    device: 'desktop',
    pageType: [
      'home',
      'category',
      'author',
      'database',
      'shows',
      'profile',
      'nil',
      'draft',
      'article',
    ],
  },
  {
    name: 'image_top',
    device: 'desktop',
    pageType: ['article', 'profile', 'database', 'shows'],
  },
  {
    name: 'image_top',
    device: 'mobile',
    pageType: ['article', 'profile', 'database', 'shows'],
  },
  {
    name: 'home_adhesion',
    device: 'desktop',
    pageType: ['home', 'article'],
  },
  {
    name: 'home_adhesion',
    device: 'mobile',
    pageType: ['home', 'article'],
  },
  {
    name: 'leader_top',
    device: 'desktop',
    pageType: ['home', 'article'],
  },
  {
    name: 'leader_bottom',
    device: 'desktop',
    pageType: ['article'],
    position: 'inc',
    count: 1,
  },
  {
    name: 'mobile_adhesion_top',
    device: 'mobile',
    pageType: [
      'home',
      'category',
      'author',
      'database',
      'shows',
      'profile',
      'nil',
      'draft',
      'article',
    ],
  },
  {
    name: 'mobile_adhesion_top_guest',
    device: 'mobile',
    pageType: [
      'home',
      'category',
      'author',
      'database',
      'shows',
      'profile',
      'nil',
      'draft',
      'article',
    ],
  },
  { name: 'mobile_mpu_top', device: 'mobile', pageType: ['home', 'article'] },
  {
    name: 'mobile_mpu_middle',
    device: 'mobile',
    pageType: [
      'home',
      'category',
      'author',
      'profile',
      'nil',
      'draft',
      'database',
      'shows',
    ],
  },
  {
    name: 'mobile_mpu_bottom',
    device: 'mobile',
    pageType: ['profile', 'database', 'shows'],
  },
  {
    name: 'mobile_banner_bottom',
    device: 'mobile',
    pageType: ['article'],
    position: 'inc',
    count: 1,
  },
  {
    name: 'mobile_bottom',
    device: 'desktop',
    pageType: ['article'],
    template: 'Feed',
    position: 'inc',
    count: 1,
  },
  {
    name: 'mobile_mpu_inc',
    device: 'mobile',
    pageType: ['article'],
    position: 'inc',
    count: getIncCount(),
  },
  {
    name: 'mpu_inc',
    device: 'desktop',
    pageType: ['article'],
    position: 'inc',
    count: getIncCount(),
  },
  { name: 'OOP_1', device: 'mobile', pageType: ['article'] },
];

const resetIncCounts = () => {
  initialNumber = 0;
  adUnitList.map((ad) => (ad.count = 1));
  window.incUnits = 0;
};

export const articleScrollAds = ({
  inc = 0,
  template = 'traditional',
  userStatus = 'guest',
}) => {
  const guest =
    userStatus !== 'annual' &&
    userStatus !== 'monthly' &&
    !userStatus?.includes('premium');
  const guestAdsMobile = [
    'mobile_adhesion_top_guest',
    'mobile_banner_bottom',
    'image_top',
  ];
  const subAdsMobile = ['mobile_banner_bottom', 'image_top'];
  const adUnits = isMobile()
    ? guest
      ? guestAdsMobile
      : subAdsMobile
    : ['mpu_top', 'mpu_middle', 'image_top'];

  // this is nasty, but we have to maintain a global counter for the inc units between the rendered ads and bidroll
  window.incUnits = window?.incUnits ? window?.incUnits + +inc : inc;
  // We could have 0 - 3 incremental ads per article.
  const device = isMobile() ? 'mobile' : 'desktop';
  const incUnit = isMobile() ? 'mobile_mpu_inc' : 'mpu_inc';

  for (let i = 0; i < inc; i++) {
    adUnits.push(incUnit);
  }

  const slots =
    isMobile() || template === 'Feed'
      ? returnAdUnitsByName({
          pageType: 'article',
          device,
          names: adUnits,
          template,
          userStatus,
        })
      : filterUnitNames({
          pageType: 'article',
          device: 'desktop',
          inc: inc || 0,
          template,
          userStatus,
        });

  // set targeting for next units
  window?.bidroll?.refresh(slots);
};
